body { background-color: var(--clr-2322); }
body { color: var(--clr-2323); }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: var(--clr-2323);
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.5;
font-size: 25px;

@media #{$medium-up} {
font-size: 40px;

}
}
h2 {
color: var(--clr-2323);
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.5;
font-size: 22px;

@media #{$medium-up} {
font-size: 24px;

}
}
h3 {
color: var(--clr-2323);
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 18px;

}
h4 {
color: var(--clr-2323);
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.5;
font-size: 18px;

}
h5 {
color: var(--clr-2323);
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
color: var(--clr-2323);
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.button {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-author {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
color: var(--clr-2323);
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 12px;

}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
summary {
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.5;
font-size: 18px;

}
nav.mainmenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 20px;

}
body {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 12px;

}
p { padding-bottom: 1em; }
a {color: var(--clr-2323);}
a:hover {color: var(--clr-2323);}
/* Primary:2 */
.MES2 {
background-color: var(--clr-2322);
&:hover {background-color: var(--clr-2322);}
color: var(--clr-2323);
 }
/* Primary:3 */
.MES3 {
background-color: var(--clr-2322);
color: var(--clr-2323);
 }
.MES3 {
background-color: var(--clr-2322);
color: var(--clr-2323);
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: var(--clr-2323);
 }
 }
cite.MEC3{
color: var(--clr-2323);
}
/* Secondary:4 */
.MES4 {
background-color: var(--clr-2324);
color: #ffffff;
 }
/* Secondary:5 */
.MES5 {
background-color: var(--clr-2324);
color: #ffffff;
 }
.MES5 {
background-color: var(--clr-2324);
color: #ffffff;
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: #ffffff;
 }
 }
a.MEC5 { color: #ffffff;
&:hover { color: #ffffff;}
 }
cite.MEC5{
color: #ffffff;
}
/* Alternate:6 */
.MES6 {
background-color: var(--clr-2325);
 }
/* Alternate:7 */
.MES7 {
background-color: var(--clr-2325);
 }
.MES7 {
background-color: var(--clr-2325);
 }
/* Light:8 */
.MES8 {
background-color: #ffffff;
 }
/* Light:9 */
.MES9 {
background-color: #ffffff;
 }
.MES9 {
background-color: #ffffff;
 }
/* Main Menu:11 */
nav.me-Menu.MES11 {
& .menu-item.MEC11, & .menu-item.MEC11 > div.MEC11{ & > a.MEC11{color: var(--clr-2324);
font-size: 12px;
@media #{$large-up} {
font-size: 15px;
}text-transform: uppercase;
}
 &:hover > a.MEC11{color: var(--clr-2324);
}
 }
&.horizontal > .menu-item.MEC11 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC11 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC11 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC11 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 20px;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Text Panel:12 */
.MES12 {
background-color: var(--clr-2322);
color: var(--clr-2323);
padding: 20px 40px;

 }
.MES12 {
background-color: var(--clr-2322);
color: var(--clr-2323);
padding: 20px 40px;

h1.MEC12, h2.MEC12, h3.MEC12, h4.MEC12, h5.MEC12, h6.MEC12 { color: var(--clr-2323);
 }
 }
cite.MEC12{
color: var(--clr-2323);
}
/* View More:13 */
.MES13 {
background-color: transparent;
&:hover {background-color: transparent;}
color: var(--clr-2324);
&:hover { color: var(--clr-2324);}
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
padding: 0;

border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent var(--clr-2324) transparent;
&:hover { border-color: transparent transparent var(--clr-2324) transparent; }
 }
/* Contact FAQ:15 */
details.MES15 {
& > summary {border-radius: 5px;}
& > summary{padding: 10px;}

& > article {border-width: 1px;
border-style: solid;
border-color: #ffffff;
}& > summary{border-width: 1px;
border-style:solid;
border-color: #ffffff;
}& > summary {& > div  > i{color: var(--clr-2324);
font-size: 14px;
}}
 }
/* Responsive Menu:16 */
nav.responsive-menu {
.menu-item.MEC16{background-color: var(--clr-2324);}
& .menu-item.MEC16, & .menu-item.MEC16 > div.menu-item-wrap{ & > a.MEC16, & > i{color: #ffffff;
text-align: center;
}
  }
& .menu-item.MEC16 { border:0;
border-color: #ffffff;border-style: dotted;

 border-bottom-width: 1px; }
& .sub-menu{ .sub-menu {}}

 }
/* Text Panel light:17 */
.MES17 {
background-color: var(--clr-2326);
color: var(--clr-2323);
padding: 20px 40px;

 }
.MES17 {
background-color: var(--clr-2326);
color: var(--clr-2323);
padding: 20px 40px;

h1.MEC17, h2.MEC17, h3.MEC17, h4.MEC17, h5.MEC17, h6.MEC17 { color: var(--clr-2323);
 }
 }
cite.MEC17{
color: var(--clr-2323);
}
